@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap');

body {
  font-family: Inter, Roboto, sans-serif;
  font-size: 14px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-overflow-scrolling: touch;
}

@media (min-width: 700px) {
  body {
    background-color: #d2d2d2;
  }
}

#root {
  height: 100%;
}
.material-symbols-outlined {
  font-variation-settings: 'FILL' 0, 'wght' 400, 'GRAD' 0, 'opsz' 20;
  width: 20px;
  height: 20px;
}

.card-header {
  font-variation-settings: 'FILL' 0, 'wght' 400, 'GRAD' 0, 'opsz' 24;
  font-family: 'Material Symbols Outlined';
  font-size: 24px;
  width: 24px;
  height: 24px;
}
.card-header-voucher {
  font-family: 'Material Symbols Outlined';
  font-size: 40px;
  font-variation-settings: 'FILL' 0, 'wght' 400, 'GRAD' 0, 'opsz' 40 !important;
}

.icon-red {
  color: #d90416;
}
.icon-blue {
  color: #3b82f6;
}

.icon-purple {
  color: #705cf2;
}
.icon-white {
  color: #fff;
}
.arrow-button-panel {
  font-variation-settings: 'FILL' 0, 'wght' 400, 'GRAD' 0, 'opsz' 14;
  width: 14px;
  height: 14px;
  font-size: 14px !important;
  font-weight: 700;
}
.card-button {
  width: 20px;
  height: 20px;
  color: #0d0d0d;
}

.design_background {
  background-image: url('https://resources.icash.one/images/portal-ui/pattern-balance-bg.svg');
  background-repeat: repeat;
  background-position-x: right;
  background-size: 15%;
  background-origin: border-box;
}
